<template>
  <svg width="30" height="40" class="site-logo" viewBox="0 0 30 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.8271 23.4246H15.2836H14.5489H11.0054L12.6331 34.6234H17.1994L18.8271 23.4246Z" :fill="props.secondary"/>
    <path d="M14.9953 2.29196C7.36079 2.24884 0.907471 8.64613 0.893066 16.2654C0.893066 20.8082 3.03937 24.8335 6.39567 27.3924C7.20233 28.0105 7.77852 28.8731 8.03781 29.865L8.80126 32.8121C9.07495 33.8759 10.0257 34.6091 11.1204 34.6091H12.0567L10.429 23.4246H9.07495C8.18185 23.4246 7.404 22.7489 7.34638 21.8576C7.27436 20.8801 8.05221 20.0606 9.01733 20.0606H9.07495C9.99685 20.0606 10.7459 20.7938 10.7603 21.7139L10.9188 22.8496H14.5487H15.2834H18.9134L19.0718 21.7139C19.0862 20.7938 19.8353 20.0606 20.7572 20.0606H20.7716C21.6071 20.0606 22.3561 20.6501 22.4713 21.4695C22.6298 22.5045 21.8231 23.4102 20.8148 23.4102H19.4031L17.7754 34.5947H18.7117C19.8065 34.5947 20.7572 33.8615 21.0309 32.7977L21.7943 29.8507C22.0536 28.8587 22.6442 27.9962 23.4509 27.3636C26.7783 24.8047 28.9391 20.7794 28.9391 16.2654C28.9391 8.58863 22.7018 2.33509 14.9953 2.29196Z" :fill="props.secondary"/>
    <path d="M7.92256 27.0328H7.33197C3.94686 27.0328 1.18115 24.287 1.18115 20.8943V19.888C1.18115 18.1197 2.62162 16.6821 4.3934 16.6821H10.8323C12.6041 16.6821 14.0446 18.1197 14.0446 19.888V20.8943C14.059 24.287 11.3077 27.0328 7.92256 27.0328ZM4.40781 18.5797C3.67317 18.5797 3.08257 19.1692 3.08257 19.9023V20.9087C3.08257 23.2519 4.9984 25.1496 7.33197 25.1496H7.92256C10.2705 25.1496 12.172 23.2376 12.172 20.9087V19.9023C12.172 19.1692 11.5814 18.5797 10.8467 18.5797H4.40781Z" :fill="props.primary"/>
    <path d="M22.5002 27.0328H21.9096C18.5245 27.0328 15.7588 24.287 15.7588 20.8943V19.888C15.7588 18.1197 17.1993 16.6821 18.971 16.6821H25.4099C27.1817 16.6821 28.6222 18.1197 28.6222 19.888V20.8943C28.6366 24.287 25.8853 27.0328 22.5002 27.0328ZM18.9854 18.5797C18.2508 18.5797 17.6602 19.1692 17.6602 19.9023V20.9087C17.6602 23.2519 19.576 25.1496 21.9096 25.1496H22.5002C24.8482 25.1496 26.7496 23.2376 26.7496 20.9087V19.9023C26.7496 19.1692 26.159 18.5797 25.4244 18.5797H18.9854Z" :fill="props.primary"/>
    <path d="M13.8431 21.0668L12.5898 19.6579C12.9067 19.3848 13.7566 18.7522 14.9954 18.781C16.1046 18.8097 16.868 19.3416 17.2282 19.6579L15.975 21.0668C15.8453 20.9517 15.4708 20.6786 14.9522 20.6642C14.376 20.6498 13.9583 20.9661 13.8431 21.0668Z" :fill="props.primary"/>
    <path d="M4.36463 16.6968H0V18.58H4.36463V16.6968Z" :fill="props.primary"/>
    <path d="M29.8324 16.6967H25.4678V18.5799H29.8324V16.6967Z" :fill="props.primary"/>
    <path d="M17.8908 35.4285H11.9417C11.4087 35.4285 10.9766 35.8597 10.9766 36.3917C10.9766 36.9236 11.4087 37.3548 11.9417 37.3548H17.8908C18.4238 37.3548 18.8559 36.9236 18.8559 36.3917C18.8559 35.8597 18.4238 35.4285 17.8908 35.4285Z" :fill="props.secondary"/>
    <path d="M16.7385 38.0736H13.1085C12.734 38.0736 12.4603 38.433 12.5611 38.8068C12.7628 39.5112 13.4254 40 14.16 40H15.6869C16.4216 40 17.0842 39.5112 17.2859 38.8068C17.3867 38.433 17.113 38.0736 16.7385 38.0736Z" :fill="props.secondary"/>
    <path d="M20.3829 8.17166C21.7513 11.5356 24.2434 12.9732 26.2312 13.577C26.2312 13.5626 25.7558 12.7001 25.1797 11.3919C25.1797 11.3919 26.793 13.5195 29.1986 14.0227C28.8673 4.03138 20.3829 3.38447 20.3829 3.38447C19.2881 0.854299 11.3223 -2.20778 5.28674 2.32065C-0.748834 6.84907 0.0866407 14.4683 0.0866407 14.4683C5.69008 15.1009 8.13888 11.4925 8.13888 11.4925C7.289 12.7863 5.94936 14.2671 5.63246 14.6265C6.30948 14.569 6.95769 14.4971 7.57709 14.3964C10.7317 13.5195 13.7279 11.0325 13.7279 11.0325C13.4254 11.9094 12.9212 12.6138 12.5179 13.1026C17.646 11.1619 20.3829 8.17166 20.3829 8.17166Z" :fill="props.primary"/>
  </svg>
</template>

<script setup>
  const props = defineProps({
    primary: {
      type: String,
      default: '#01498B'
    },
    secondary: {
      type: String,
      default: '#99B7D0'
    }
  })
</script>