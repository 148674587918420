<template>
  <div class="site-logo-title">
    <div
      class="site-logo-title__main"
    >
      Freelance IT Tools
    </div>
    <div
      class="site-logo-title__sub"
    >
      Outils de gestion pour Freelances
    </div>
  </div>
</template>

<script setup>

</script>

<style lang="scss">
  @use './index.scss';
</style>