export default function () {
  const config = useRuntimeConfig()

  const loc = new URL(config.public.siteUrl).hostname.split('.')
  loc.shift()
  loc.unshift('')

  const identity = useCookie<false | {
    isTemp: boolean,
    email: string,
    name: string,
    firstname: string,
    lastname: string,
    signature?: string
    companies: any[]
  }>('identity', {
    default: () => false,
    domain: loc.join('.'),
    secure: true
  })

  const authentity = useCookie<false | string>('sessionKey', {
    default: () => false,
    domain: loc.join('.'),
    secure: true
  })
  
  const identify = (value: any) => {
    identity.value = value
  }

  const authenticate = async () => {
    if (identity.value) {
      const res: {
        sessionId: string
      } = await $fetch(`${config.public.apiBaseUrl}/connexion`, {
        method: 'POST',
        body: {
          email: identity.value.email,
        }
      })
  
      authentity.value = res.sessionId
    }
  }

  const synchronizeAuthAndIdent = async (withoutPictures: boolean, mounted: boolean) => {
    if (authentity.value) {
      const url = `${config.public.apiBaseUrl}/me?withoutPictures=${withoutPictures}&auth_token=${authentity.value}`
      let me
      if (mounted) {
        me =  await $fetch(url)
      } else {
        me =  (await useFetch(url)).data.value
      }
      identify(me)
    } else if (identity.value && identity.value.isTemp) {
      await authenticate()
    }
  }

  const resetIdentity = async () => {
    if (authentity.value) {
      await $fetch(`${config.public.apiBaseUrl}/session?auth_token=${authentity.value}`, {
        method: 'DELETE'
      })
      authentity.value = false
    }
    identity.value = false
  }

  return {
    authenticate,
    resetIdentity,
    identify,
    identity,
    authentity,
    synchronizeAuthAndIdent
  }
}